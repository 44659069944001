
import { Component, Prop, Vue } from 'vue-property-decorator';
import KpiWidget from '../common/KpiWidget.vue';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { BendingAvailabilityDataPerDevice } from '@/models/Charts/bendingAvailabilityKpiWidgetGenerator';

@Component({
  components: {
    KpiWidget,
  },
})
export default class AvailabilityKpiWidget extends Vue {
  @Prop({ required: true })
  private data!: BendingAvailabilityDataPerDevice[] | null;

  @Prop({ required: true })
  private generator!: ChartGenerator<BendingAvailabilityDataPerDevice>;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;

  private mappingFunction(entry: BendingAvailabilityDataPerDevice) {
    return entry.bend;
  }
}
